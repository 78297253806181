var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.readableAndActionHeaders,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{
    'items-per-page-options': _vm.itemsPerPageOptions
  }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('div',[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title || "Danh sách " + _vm.itemName)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.subtitle))])],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"hide-details":"","placeholder":"Tìm kiếm","prepend-inner-icon":"mdi-magnify","clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('v-spacer'),_vm._t("actions"),_c('v-dialog',{attrs:{"max-width":"500px","scrollable":"","persistent":""},scopedSlots:_vm._u([(_vm.creatable)?{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Tạo mới "+_vm._s(_vm.itemName)+" ")])]}}:null],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"font-weight-bold h5"},[_vm._v(_vm._s(_vm.isCreateNew ? "Tạo mới" : "Sửa")+" "+_vm._s(_vm.itemName.toLowerCase()))])]),_c('v-card-text',{staticStyle:{"max-height":"80vh"}},[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_vm._l((_vm.editableHeaders),function(header){return [(header.dataType === 'datetime')?_c('DatePicker',{key:header.value,attrs:{"label":header.text,"rules":(header.rules &&
                      header.rules
                        .split('|')
                        .map(function (r) { return _vm.rules[r](header.dataType); })) ||
                      []},model:{value:(_vm.editedItem[header.value]),callback:function ($$v) {_vm.$set(_vm.editedItem, header.value, $$v)},expression:"editedItem[header.value]"}}):(header.dataType === 'number')?_c('v-text-field',{key:header.value,attrs:{"label":header.text},model:{value:(_vm.editedItem[header.value]),callback:function ($$v) {_vm.$set(_vm.editedItem, header.value, $$v)},expression:"editedItem[header.value]"}}):(header.dataType === 'boolean')?_c('v-checkbox',{key:header.value,attrs:{"label":header.text,"rules":(header.rules &&
                      header.rules
                        .split('|')
                        .map(function (r) { return _vm.rules[r](header.dataType); })) ||
                      []},model:{value:(_vm.editedItem[header.value]),callback:function ($$v) {_vm.$set(_vm.editedItem, header.value, $$v)},expression:"editedItem[header.value]"}}):(header.dataType === 'choice')?_c('v-select',{key:header.value,attrs:{"items":header.data,"label":header.text,"rules":(header.rules &&
                      header.rules
                        .split('|')
                        .map(function (r) { return _vm.rules[r](header.dataType); })) ||
                      [],"item-text":"text","item-value":"value"},model:{value:(_vm.editedItem[header.value]),callback:function ($$v) {_vm.$set(_vm.editedItem, header.value, $$v)},expression:"editedItem[header.value]"}}):(header.dataType === 'multiple-choice')?_c('v-select',{key:header.value,attrs:{"items":header.data,"label":header.text,"rules":(header.rules &&
                      header.rules
                        .split('|')
                        .map(function (r) { return _vm.rules[r](header.dataType); })) ||
                      [],"multiple":"","item-text":"text","item-value":"value"},model:{value:(_vm.editedItem[header.value]),callback:function ($$v) {_vm.$set(_vm.editedItem, header.value, $$v)},expression:"editedItem[header.value]"}}):(header.dataType === 'belongs-to')?_c('ServerAutocomplete',{key:header.value,attrs:{"label":header.text,"item-text":header.dataText,"url":header.dataUrl,"rules":(header.rules &&
                      header.rules
                        .split('|')
                        .map(function (r) { return _vm.rules[r](header.dataType); })) ||
                      [],"return-object":"","item-value":"id"},model:{value:(_vm.editedItem[header.dataValue]),callback:function ($$v) {_vm.$set(_vm.editedItem, header.dataValue, $$v)},expression:"editedItem[header.dataValue]"}}):(header.dataType === 'has-many')?_c('ServerAutocomplete',{key:header.value,attrs:{"label":header.text,"item-text":header.dataText,"url":header.dataUrl,"rules":(header.rules &&
                      header.rules
                        .split('|')
                        .map(function (r) { return _vm.rules[r](header.dataType); })) ||
                      [],"multiple":"","return-object":"","item-value":"id"},model:{value:(_vm.editedItem[header.dataValue]),callback:function ($$v) {_vm.$set(_vm.editedItem, header.dataValue, $$v)},expression:"editedItem[header.dataValue]"}}):_c('v-text-field',{key:header.value,attrs:{"label":header.text,"rules":(header.rules &&
                      header.rules
                        .split('|')
                        .map(function (r) { return _vm.rules[r](header.dataType); })) ||
                      []},model:{value:(_vm.editedItem[header.value]),callback:function ($$v) {_vm.$set(_vm.editedItem, header.value, $$v)},expression:"editedItem[header.value]"}})]})],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Hủy")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.editLoading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.isCreateNew ? "Tạo mới" : "Cập nhật")+" ")])],1)],1)],1),(_vm.removable)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"h5 font-weight-bold"},[_vm._v(" Xóa "+_vm._s(_vm.itemName)+" ")]),_c('v-card-text',[_vm._v(" Bạn có chắc chắn muốn xóa "+_vm._s(_vm.itemName)+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeDelete}},[_vm._v(" Hủy")]),_c('v-btn',{attrs:{"color":"error","loading":_vm.deleteLoading},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" Xóa ")])],1)],1)],1):_vm._e()],2)]},proxy:true},_vm._l((_vm.readableHeaders),function(header){return {key:("item." + (header.value)),fn:function(ref){
                      var item = ref.item;
return [_vm._t(("item." + (header.value)),[(header.dataType === 'datetime')?_c('div',{key:header.value},[_vm._v(" "+_vm._s(_vm._f("dateTime")(item[header.value]))+" ")]):(header.dataType === 'boolean')?_c('v-simple-checkbox',{key:header.value,attrs:{"value":item[header.value],"ripple":false,"color":"primary"}}):(header.dataType === 'belongs-to')?_c('div',{key:header.value},[_vm._v(" "+_vm._s(item[header.dataValue][header.dataText])+" ")]):(header.dataType === 'has-many')?_c('div',{key:header.value},_vm._l((item[header.dataValue].map(
            function (i) { return i[header.dataText]; }
          )),function(val,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(val)+" ")])}),0):(header.dataType === 'choice')?_c('div',{key:header.value},[_vm._v(" "+_vm._s((header.data.find(function (i) { return i.value === item[header.value]; }) && header.data.find(function (i) { return i.value === item[header.value]; }).text) || item[header.value])+" ")]):(header.dataType === 'multiple-choice')?_c('div',{key:header.value},_vm._l((item[header.dataValue].map(
            function (i) { return i[header.dataText]; }
          )),function(val,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(val)+" ")])}),0):_c('div',{key:header.value},[_vm._v(" "+_vm._s(item[header.value])+" ")])],{"item":item})]}}}),{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_vm._t("item-actions",null,{"item":item}),(_vm.editable)?_c('v-btn',{attrs:{"title":"Edit","icon":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil")])],1):_vm._e(),(_vm.removable)?_c('v-btn',{attrs:{"icon":"","title":"Delete"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v(" mdi-delete")])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fetchData}},[_vm._v(" Refresh ")])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }